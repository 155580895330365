import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Grid, Container, Paper } from '@material-ui/core';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';

import { PageLayout } from '../layouts';
import { Box, Typography, ButtonLink } from '../components';
import { truncateStringSeoFriendly } from '../helpers/truncate-string';

function MultiContentPageTemplate({ data, pageContext, path }) {
  const { images, childImages } = data;
  const { body, childrenData, excerpt, keywords, title } = pageContext;
  return (
    <PageLayout
      description={truncateStringSeoFriendly(excerpt)}
      title={title}
      image={images.nodes && images.nodes[0] ? images.nodes[0].childImageSharp.resize : null}
      keywords={keywords}
      pathnames={[path, ...childrenData.map(({ path: childPaths }) => childPaths)]}
    >
      <Box bgColorClass="contrastText">
        <Container maxWidth="lg">
          <Grid container direction="row" justify="space-evenly" alignItems="center">
            <Grid item xs={12} style={{ paddingTop: '5vh' }}>
              <Paper elevation={4} style={{ maxWidth: 1200, maxHeight: 650 }}>
                <Img fluid={images.nodes[0].childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%" />
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '5vh', paddingBottom: '5vh' }}>
              <MDXRenderer title={title} linkProps={{ path }} typographyProps={{ colorClass: 'primary' }}>
                {body}
              </MDXRenderer>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgColorClass="contrastText" marginClass="marginBottom">
        <Container maxWidth="lg">
          <Grid container direction="row" justify="space-around" alignItems="center">
            {childrenData.map((child) => {
              const image = childImages.nodes.find((f) => f.childImageSharp.fluid.originalName === child.contentImages[0]);
              return (
                <Grid key={child.id} item xs={12} sm={6} md={4} style={{ marginBottom: 25, padding: 10 }}>
                  <Paper elevation={4} style={{ maxWidth: 450, height: 500 }}>
                    <div style={{ paddingBottom: 15 }}>
                      <Img fluid={image.childImageSharp.fluid} />
                    </div>
                    <Grid container direction="column">
                      <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <Typography variant="body1" gutterBottom>
                          <b>{child.title}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <Typography variant="body1" align="justify" gutterBottom paragraph>
                          {truncateStringSeoFriendly(child.excerpt)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ padding: 15 }}>
                        <ButtonLink linkProps={{ to: child.path }} buttonProps={{ variant: 'outlined', color: 'secondary', size: 'small' }}>
                          weitere Informationen
                        </ButtonLink>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </PageLayout>
  );
}

export const query = graphql`
  query ProductPageTemplateQuery($contentImages: [String!], $childImages: [String!]) {
    images: allFile(filter: { relativePath: { in: $contentImages } }) {
      nodes {
        id
        childImageSharp {
          fluid(maxHeight: 650, maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
    childImages: allFile(filter: { relativePath: { in: $childImages } }) {
      nodes {
        id
        childImageSharp {
          fluid(maxHeight: 300, maxWidth: 450, quality: 100) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

MultiContentPageTemplate.propTypes = {
  images: PropTypes.array,
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

MultiContentPageTemplate.defaultProps = { images: null };

export default MultiContentPageTemplate;
